<template>
    <div class="referral">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <nav class="navbar navbar-expand-lg">
                <el-breadcrumb separator-class="el-icon-arrow-right" style="padding: 0 15px">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>医疗专属服务</el-breadcrumb-item>
                    <el-breadcrumb-item>转诊管理</el-breadcrumb-item>
                </el-breadcrumb>
                <head-center></head-center>
            </nav>
        </div>
        <!--    内容    -->
        <div class="center-content">
            <el-divider content-position="left">近期转诊</el-divider>
            <el-row :gutter="20">
                <el-col :span="6" v-for="(recentItem, index) in recentList.slice(0, 4)" :key="index">
                    <div class="iq-card-body">
                        <div class="list-top clearfix">
                            <div class="list-img left">
                                <img src="../../../assets/img/login_img.png" alt="">
                            </div>
                            <div class="list-mes left">
                                <h3>{{ recentItem.referralHospitalName }}</h3>
                                <p>账号：{{ recentItem.referralPhone }}</p>
                            </div>
                        </div>
                        <div class="list-bottom clearfix">
                            <p class="left">医生：{{ recentItem.referralName }}</p>
                            <p class="right">病人：{{ recentItem.name }}</p>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <div class="iq-card-body" style="margin-top: 20px;">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :model="searchForm" :inline="true">
                            <el-form-item>
                                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions0" size="small" style="margin-right: 10px">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small"
                                    placeholder="输入姓名或手机号..." @keyup.enter.native="search"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                                <el-button type="primary" size="small" @click="AddReferralBtn" plain>新增</el-button>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="tableData.length>0" :data="tableData" :fields="json_fields"
                                    worksheet="My Worksheet" :header="title" :name="`${title}.xls`" class="excel">
                                    <el-button type="warning" size="mini" class="el-icon-download">导出当前数据</el-button>
                                </download-excel>
                            </el-form-item>
                            <el-form-item>
                                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData"
                                    worksheet="My Worksheet" header="全部转诊" :name="`全部转诊_All.xls`" class="excel">
                                    <el-button type="danger" size="mini" class="el-icon-download">导出全部数据</el-button>
                                </download-excel>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-tabs v-model="state" @tab-click="handleClick">
                    <el-tab-pane :label="item.labelName" :name="item.state" v-for="(item, index) in tabList"
                        :key="index">
                        <el-table class="tab" :data="tableData" stripe style="width: 100%" @cell-click="toDetail">
                            <el-table-column prop="name" label="患者姓名">
                            </el-table-column>
                            <el-table-column prop="phone" label="患者手机号">
                            </el-table-column>
                            <el-table-column prop="diagnosis" label="初步诊断">
                            </el-table-column>
                            <el-table-column prop="degree" label="病情" :formatter="ReferralDegree">
                            </el-table-column>
                            <el-table-column label="接诊医生" width="250">
                                <template slot-scope="scope">
                                    <p>{{ scope.row.receptionName }}<span v-if="scope.row.receptionPhone">【{{
                                    scope.row.receptionPhone
                                    }}】</span></p>
                                </template>
                            </el-table-column>
                            <el-table-column prop="receptionHospitalName" label="接诊医院">
                            </el-table-column>
                            <el-table-column prop="receptionDermant" label="接诊科室">
                            </el-table-column>
                            <el-table-column prop="referralTime" label="转诊时间">
                                <template slot-scope="scope">
                                    <p>{{ scope.row.referralTime | timefilters }}</p>
                                </template>
                            </el-table-column>
                            <el-table-column prop="state" label="状态" :formatter="ReferralState" width="100">
                            </el-table-column>
                            <el-table-column fixed="right" label="操作" width="220">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.state == '-1'" class="primary optionBtn"
                                        @click="ReferralBtn(scope.row)">转诊</span>
                                    <span class="warning optionBtn" v-if="scope.row.state == '-1'"
                                        @click="EditReferralBtn(scope.row)" >编辑</span>
                                    <span v-if="scope.row.state == '0'" class="danger optionBtn"
                                        @click="RemoveUndo(scope.row)">撤回</span>
                                    <span v-if="scope.row.state == '-1' || scope.row.state == '11'" class="danger optionBtn"
                                        @click="RemovePost(scope.row)">删除</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
        <!--    转诊弹框    -->
        <el-dialog title="转诊处理" :visible.sync="referraldialog">
            <el-form :model="referralform" ref="referralform" :rules="rules">
                <el-form-item label="是否加急" :label-width="formLabelWidth">
                    <el-switch :active-value="1" :inactive-value="0" v-model="referralform.severity"
                        active-color="#13ce66">
                    </el-switch>
                </el-form-item>
                <el-form-item label="转诊原因" :label-width="formLabelWidth" prop="reason">
                    <el-select v-model="referralform.reason" placeholder="请选择转诊原因">
                        <el-option label="技术缺乏" value="1"></el-option>
                        <el-option label="设备缺乏" value="2"></el-option>
                        <el-option label="诊断疑问" value="3"></el-option>
                        <el-option label="条件有限" value="4"></el-option>
                    </el-select>
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="referralform.content"
                        style="margin-top: 15px;">
                    </el-input>
                </el-form-item>
                <el-form-item label="接诊医院" :label-width="formLabelWidth" prop="hospitalId">
                    <!-- <el-select v-model="referralform.hospitalId" placeholder="请选择接诊医院" @change="selectHospital">
                        <el-option v-for="item in HospitalData" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select> -->
                    <el-cascader ref="myCascader" :options="HospitalData"
                        :props="{ expandTrigger: 'hover', emitPath: false, multiple: false, checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }"
                        v-model="referralform.hospitalId" :show-all-levels="false" @change="selectHospital">
                    </el-cascader>
                    <el-checkbox style="margin-left: 15px;" v-model="referralform.green">直接转诊到该医院的绿色通道</el-checkbox>
                </el-form-item>
                <el-form-item label="接诊科室" prop="departmentId" :label-width="formLabelWidth" v-if="!referralform.green">
                    <el-cascader ref="myCascader" :options="DepartmentData"
                        :props="{ expandTrigger: 'hover', emitPath: false, multiple: false, checkStrictly: true, value: 'id', label: 'name', children: 'childrens' }"
                        v-model="referralform.departmentId" :show-all-levels="false" @change="selectDepartment">
                    </el-cascader>
                </el-form-item>
                <!--<el-form-item label="接诊科室" :label-width="formLabelWidth">
                    <el-select v-model="referralform.departmentId" placeholder="请选择接诊医院" >
                        <el-option v-for="item in DepartmentData"
                                   :label="item.name"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>-->
                <el-form-item label="接诊医生" :label-width="formLabelWidth" prop="doctorId" v-if="!referralform.green">
                    <el-select v-model="referralform.doctorId" placeholder="请选择接诊医生">
                        <el-option v-for="item in DoctorData" :label="item.userName" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialog('referralform')">取 消</el-button>
                <el-button type="primary" @click="SaveUpload('referralform')">确 定</el-button>
            </div>
        </el-dialog>
        <!--    新增转诊    -->
        <el-drawer :title="ReferralDrawerTitle" :visible.sync="AddReferralDrawer" direction="ttb" :size=size>
            <div class="addreferral">
                <el-form ref="AddReferralform" :rules="rules" :model="AddReferralform" label-width="100px">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="姓名" prop="name">
                                <el-input v-model="AddReferralform.name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="性别">
                                <el-radio v-model="AddReferralform.sex" label="0">男</el-radio>
                                <el-radio v-model="AddReferralform.sex" label="1">女</el-radio>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="年龄" prop="age">
                                <el-input v-model="AddReferralform.age"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="联系电话" prop="phone">
                                <el-input v-model="AddReferralform.phone"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="病情程度">
                                <el-select v-model="AddReferralform.degree" placeholder="请选择病情程度" style="width: 100%">
                                    <el-option label="轻度" value="1"></el-option>
                                    <el-option label="中度" value="2"></el-option>
                                    <el-option label="重度" value="3"></el-option>
                                    <el-option label="严重" value="4"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="身份证号" prop="identity">
                                <el-input v-model="AddReferralform.identity"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="初步诊断" prop="diagnosis">
                                <el-input v-model="AddReferralform.diagnosis"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="患者介绍">
                                <quill-editor v-model="AddReferralform.introduction" ref="myQuillEditor"
                                    :content="AddReferralform.introduction" :options="editorOption"
                                    @change="onEditorChange($event)" />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!--<tinymce-editor ref="editor" v-model="value"></tinymce-editor>-->
                    <el-form-item>
                        <el-button type="primary" @click="SaveReferralform('AddReferralform')">保存</el-button>
                        <el-button @click="cancelDialog('AddReferralform')">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-drawer>
        <el-drawer title="详情" :visible.sync="DetailDrawer" direction="rtl" size="550px">
            <div class="drawer-box">
                <div class="detail-item">
                    <h5 class="detail-tit">患者信息</h5>
                    <el-descriptions title="" :column="2" border size="medium">
                        <el-descriptions-item label="姓名">{{ dataDetail.name }}</el-descriptions-item>
                        <el-descriptions-item label="年龄">{{ dataDetail.age || '-' }}</el-descriptions-item>
                        <el-descriptions-item label="性别">{{ dataDetail.sex == 0 ? '男' : '女' }}</el-descriptions-item>
                        <el-descriptions-item label="手机号">{{ dataDetail.phone }}</el-descriptions-item>
                        <el-descriptions-item label="身份证号">{{ dataDetail.identity || '-' }}</el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions title="" :column="1" border size="medium">
                        <el-descriptions-item label="初步诊断" :span="2">{{ dataDetail.diagnosis }}</el-descriptions-item>
                        <el-descriptions-item label="患者介绍" :span="2"><i v-html="dataDetail.introduction"></i>
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
                <div class="detail-item">
                    <h5 class="detail-tit">时间轴</h5>
                    <div class="block">
                        <el-timeline :reverse="true">
                            <el-timeline-item v-for="(activity, index) in timelines" :key="index" :icon="activity.icon"
                                :type="activity.type" color="#5c83f2" :size="activity.size">
                                {{ activity.receptionTime | timefilters }}
                                <el-card>
                                    <h4>{{ activity.remark }}</h4>
                                    <p>{{ activity.handHospitalName }}——{{ activity.handDermantName }}</p>
                                    <p>医生：{{ activity.handler }}</p>
                                </el-card>
                            </el-timeline-item>
                        </el-timeline>
                        <div class="no-data" v-show="timelines == ''">
                            <!-- <img src="../../assets/img/no-data1.png" alt=""> -->
                            <p>暂无时间轴信息</p>
                        </div>
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { Referral } from '../../../components/Referral/Referral';
import { Organization } from '../../../components/HospitalDomain/Organization';
import { User } from '../../../components/HospitalDomain/User';
export default {
    name: "Referral",
    //注册局部过滤器
    filters: {
        timefilters(val) {
            if (val == null || val == "") {
                return "暂无时间";
            } else {
                let d = new Date(val);   //val 为表格内取到的后台时间
                let month =
                    d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
                let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
                let hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
                let min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
                let sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
                let times = d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;
                return times;
            }
        }
    },
    data() {
        var referral = new Referral(this.TokenClient, this.Services.Referral);
        var organization = new Organization(this.TokenClient, this.Services.Authorization);
        var user = new User(this.TokenClient, this.Services.Authorization);
        return {
            Referral: referral,
            Organization: organization,
            User: user,
            state: '99',
            timelines: [],
            DetailDrawer: false,
            dataDetail: {},
            datepicker: '',
            recentList: [],
            tableData: [],
            HospitalData: [],
            DepartmentData: [],
            HospitalDepartmentId: [],
            DoctorData: [],
            organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
            referraldialog: false,
            AddReferralDrawer: false,
            ReferralDrawerTitle: '',
            size: '100%',
            referralform: {
                id: 0,
                severity: false,
                reason: '',
                content: '',
                hospitalId: '',
                green: false,
                departmentId: '',
                doctorId: '',
            },
            searchForm: {
                statrTime: '',
                endTime: '',
                keyWord: '',
                datepicker: ''
            },
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            tabList: [
                {
                    labelName: "全部转诊",
                    state: '99'
                }, {
                    labelName: "待转诊",
                    state: '-1'
                }, {
                    labelName: "待处理",
                    state: '0'
                }, {
                    labelName: "处理中",
                    state: '10'
                }, {
                    labelName: "已完成",
                    state: '11'
                },
            ],
            PageIndex: 1,
            PageTotal: 1,
            PageSize: 15,
            DataTotal: 0,
            departmentName: '',
            AddReferralform: {
                id: 0,
                name: '',
                sex: '',
                age: '',
                phone: '',
                degree: '',
                identity: '',
                diagnosis: '',
                introduction: '1111'
            },
            editorOption: {
                placeholder: '编辑文章内容'
            },
            formLabelWidth: '120px',
            rules: {
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                sex: [
                    { required: true, message: '请选择性别', trigger: 'blur' }
                ],
                age: [
                    { pattern: /^(\d|[1-9]\d|1[0-1]\d|120)$/, message: "请输入不大于120的整数", trigger: "blur" }
                ],
                phone: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' },
                    { pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur" }
                ],
                identity: [
                    { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: "请输入正确格式的身份证号码", trigger: "blur" }
                ],
                degree: [
                    { required: true, message: '请选择病情程度', trigger: 'blur' }
                ],
                diagnosis: [
                    { required: true, message: '请输入初步诊断', trigger: 'blur' }
                ],
                reason: [
                    { required: true, message: '请选择转诊原因', trigger: 'blur' }
                ],
                hospitalId: [
                    { required: true, message: '请选择医院', trigger: 'blur' }
                ],
                departmentId: [
                    { required: true, message: '请选择科室', trigger: 'blur' }
                ],
                doctorId: [
                    { required: true, message: '请选择医生', trigger: 'blur' }
                ],

            },
            json_fields: {
                "患者姓名": 'name',
                "患者手机号": 'phone',
                "初步诊断": 'diagnosis',
                "病情": {
                    filed: 'degree',
                    callback: val => {
                        var str = ''
                        var val = val.degree
                        switch (val) {
                            case '1':
                                str = '轻度'
                                break;
                            case '2':
                                str = '中度'
                                break;
                            case '3':
                                str = '重度'
                                break;
                            case '4':
                                str = '严重'
                                break;
                        }
                        return str
                    }
                },
                "接诊医生": 'receptionName',
                "接诊医生手机号": 'receptionPhone',
                "接诊医院": 'receptionHospitalName',
                "接诊科室": 'receptionDermant',
                "转诊时间": {
                    filed: 'referralTime',
                    callback: (val) => {
                        var val = val.referralTime
                        return this.$fliters.timefilters(val)
                    }
                },
                "状态": {
                    filed: 'state',
                    callback: val => {
                        var str = ''
                        var val = val.state
                        switch (val) {
                            case -1:
                                str = '待转诊'
                                break;
                            case 10:
                                str = '处理中'
                                break;
                            case 0:
                                str = '待处理'
                                break;
                            case 11:
                                str = '已完成'
                                break;
                        }
                        return str
                    }
                },
            },
            title: '全部转诊',
            DataCount: 0
        };
    },
    computed: {
        editor() {
            return this.$refs.myQuillEditor.quill;
        }
    },
    mounted() {
        var _this = this;
        _this.FindAllReferral();
        _this.getRecent();
    },
    methods: {
        //患者详情
        toDetail(row, column, cell, event) {
            if (column.label != "操作") {
                this.DetailDrawer = true
                var _this = this;
                _this.Referral.refeDetail(row.referralId,
                    function (data) {
                        _this.dataDetail = data.data;
                    },
                    function (error) {
                        console.log(error);
                    });
                _this.Referral.TimeLine(row.referralId,
                    function (data) {
                        _this.timelines = data.data;
                    },
                    function (error) {
                        console.log(error);
                    });
            }
        },
        search() {
            var _this = this
            _this.PageIndex = 1;
            _this.tableData = [];
            switch (_this.state) {
                case '99':
                    _this.FindAllReferral();
                    break;
                default:
                    _this.getReferral()
                    break;
            }
        },
        getReferral() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker) {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            } else {
                item.startTime = "";
                item.endTime = "";
            }
            _this.Referral.getReferral(_this.state, _this.PageIndex, item.startTime, item.endTime, item.keyWord,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                },
                function (error) {
                    console.log(error);
                });
        },
        ChangePage(pageIndex) {
            var _this = this
            _this.PageIndex = pageIndex;
            _this.tableData = [];
            switch (_this.state) {
                case '99':
                    _this.FindAllReferral();
                    break;
                default:
                    _this.getReferral()
                    break;
            }
        },
        //角色类型状态
        ReferralSex(row) {
            return row.sex == 1 ? '女' : '男'
        },
        //状态 对应状态码
        ReferralDegree(row) {
            if (row.degree == '1') {
                return '轻度'
            } else if (row.degree == '2') {
                return '中度';
            } else if (row.degree == '3') {
                return '重度';
            } else if (row.degree == '4') {
                return '严重';
            }
        },
        ReferralState(row) {
            if (row.state == '-1') {
                return '待转诊'
            } else if (row.state == '10') {
                return '处理中';
            } else if (row.state == '0') {
                return '待处理';
            } else if (row.state == '11') {
                return '已完成';
            }
        },
        async fetchData() {
            var _this = this;
            return await _this.getAllData()
        },
        getAllData() {
            var _this = this;
            return new Promise((resolve, reject) => {
                var item = _this.searchForm;
                if (_this.searchForm.datepicker) {
                    item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                    item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
                } else {
                    item.startTime = "";
                    item.endTime = "";
                }
                _this.Referral.AllReferral(1, item.startTime, item.endTime, item.keyWord, _this.DataCount,
                    function (data) {
                        resolve(data.data.results);
                    },
                    function (error) {
                        console.log(error);
                        resolve('');
                    });
            })

        },
        FindAllReferral() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker) {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            } else {
                item.startTime = "";
                item.endTime = "";
            }
            _this.Referral.AllReferral(_this.PageIndex, item.startTime, item.endTime, item.keyWord, null,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.PageTotal = data.data.pageCount;
                    _this.DataTotal = data.data.dataTotal;
                    if (!item.keyWord) {
                        _this.DataCount = data.data.dataTotal;
                    }
                },
                function (error) {
                    console.log(error);
                });
        },
        handleClick(tab) {
            var _this = this;
            _this.PageIndex = 1;
            _this.tableData = [];
            _this.title = tab.label
            switch (tab.name) {
                case '99':
                    _this.FindAllReferral();
                    break;
                default:
                    _this.getReferral()
                    break;
            }
        },
        //近期转诊
        getRecent() {
            var _this = this;
            _this.Referral.Recent(
                function (data) {
                    _this.recentList = data.data;
                },
                function (error) {
                    console.log(error);
                });
        },
        selectHospital(data) {
            this.referralform.hospitalId = data;
            var _this = this;
            _this.Organization.DepartmentTree(data, function (data) {
                _this.DepartmentData = data.data;

            }, function (err) { console.log(err); });
        },
        selectDepartment(value) {
            console.log(value)
            var _this = this;
            _this.departmentName = this.$refs.myCascader.getCheckedNodes()[0].label;
            _this.User.RefeUser(this.referralform.hospitalId, value, function (data) {
                _this.DoctorData = data.data;
            }, function (err) {
                console.log(err);
            });
        },
        ReferralBtn(item) {
            var _this = this;
            _this.referraldialog = true;
            _this.referralform.severity = false;
            _this.referralform.referralId = item.referralId;
            _this.referralform.reason = '';
            _this.referralform.content = '';
            _this.referralform.hospitalId = '';
            _this.referralform.green = false;
            _this.referralform.departmentId = '';
            _this.referralform.doctorId = '';
            _this.Organization.RefeHospital(this.organizationId,
                function (data) {
                    _this.HospitalData = data.data;
                }, function (err) {
                    console.log(err);
                });
        },
        SaveUpload(formName) {
            var _this = this;
            var item = _this.referralform;
            var Hospital = _this.HospitalData.find(function (x) {
                return x.id === item.hospitalId
            });
            /*var Department=_this.DepartmentData.find(function (x) {
                return x.id === item.departmentId
            });*/
            var Doctor = {
                id: '',
                phone: "",
                userName: ""
            }
            if (_this.DoctorData.length > 0) {
                Doctor = _this.DoctorData.find(function (x) {
                    return x.id === item.doctorId
                })
            }
            console.log(item)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    _this.Referral.Upload(item.referralId, item.severity ? 1 : 0, item.reason, item.content, item.doctorId, item.hospitalId, Hospital.phone, Hospital.name, _this.departmentName, Doctor.phone, Doctor.userName, function (data) {
                        _this.referraldialog = false;
                        _this.pageIndex = 1
                        switch (_this.state) {
                            case '99':
                                _this.FindAllReferral();
                                break;
                            default:
                                _this.getReferral()
                                break;
                        }
                        console.log("转诊");
                    },
                        function (err) {
                            console.log(err);
                        }
                    )

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        AddReferralBtn() {
            this.AddReferralDrawer = true;
            this.ReferralDrawerTitle = '新增';
            this.AddReferralform.id = null;
            this.AddReferralform.name = '';
            this.AddReferralform.sex = '0';
            this.AddReferralform.age = '';
            this.AddReferralform.phone = '';
            this.AddReferralform.degree = '';
            this.AddReferralform.identity = '';
            this.AddReferralform.diagnosis = '';
            this.AddReferralform.introduction = '';
        },
        EditReferralBtn(item) {
            this.AddReferralDrawer = true;
            this.ReferralDrawerTitle = '编辑';
            this.AddReferralform = item;
            this.AddReferralform.sex = item.sex.toString();
        },
        SaveReferralform(formName) {
            var _this = this;
            var item = _this.AddReferralform
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (item.referralId) {
                        _this.Referral.EditReferral(item.referralId, item.name, parseInt(item.sex), item.age, item.phone, item.identity, item.degree, item.diagnosis, item.introduction, function (data) {
                            _this.AddReferralDrawer = false;
                            _this.PageIndex = 1;
                            switch (_this.state) {
                                case '99':
                                    _this.FindAllReferral();
                                    break;
                                default:
                                    _this.getReferral()
                                    break;
                            }
                        },
                            function (err) {
                                console.log(err);
                            }
                        )
                    } else {
                        _this.Referral.AddReferral(item.name, parseInt(item.sex), item.age, item.phone, item.identity, item.degree, item.diagnosis, item.introduction, function (data) {
                            _this.AddReferralDrawer = false;
                            _this.PageIndex = 1;
                            switch (_this.state) {
                                case '99':
                                    _this.FindAllReferral();
                                    break;
                                default:
                                    _this.getReferral()
                                    break;
                            }
                        },
                            function (err) {
                                console.log(err);
                            }
                        )
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        },
        cancelDialog(formName) {
            var _this = this;
            _this.AddReferralDrawer = false;
            _this.referraldialog = false;
            this.$refs[formName].resetFields();
        },
        RemovePost(item) {
            var _this = this;
            this.$confirm('确定删除此条转诊记录吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.Referral.Remove(item.referralId, function (data) {
                    /*_this.FindAllReferral();*/
                    _this.pageIndex = 1
                    switch (_this.state) {
                        case '99':
                            _this.FindAllReferral();
                            break;
                        default:
                            _this.getReferral()
                            break;
                    }
                    _this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }, function (err) {
                    console.log(err);
                });
            }).catch(() => { });
        },
        RemoveUndo(item) {
            var _this = this;
            this.$confirm('确定撤回此条转诊记录吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.Referral.Undo(item.referralId, function (data) {
                    _this.pageIndex = 1
                    switch (_this.state) {
                        case '99':
                            _this.FindAllReferral();
                            break;
                        default:
                            _this.getReferral()
                            break;
                    }
                    _this.$message({
                        type: 'success',
                        message: '撤回成功!'
                    });
                }, function (err) {
                    console.log(err);
                });
            }).catch(() => {
                _this.$message({
                    type: 'info',
                    message: '已取消撤回'
                });
            });
        },
        onEditorChange({ editor, html, text }) {
            this.content = html;
        },
    },

}
</script>

<style scoped>
/*content*/



.list-img {
    margin-right: 15px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #e5e5e5;
}

.list-img img {
    width: 100%;
    height: 100%;
}

.list-mes {
    padding: 10px 0;
}

.list-bottom {
    margin-top: 15px;
}

.iq-search-bar {
    display: inline-block;
    margin-right: 20px;
}

.iq-search-bar .searchbox {
    width: 480px;
    position: relative;
}

.iq-search-bar .searchbox .search-link {
    position: absolute;
    right: 15px;
    top: 2px;
    font-size: 16px;
}

.iq-search-bar .search-input {
    width: 100%;
    padding: 5px 40px 5px 15px;
    border-radius: 10px;
    border: none;
    background: #cbdbf7;
    line-height: 1.8;
}

.addreferral {
    width: 80%;
    margin: 0 auto;
}

.addreferral .el-form {
    width: 100%;
}

.drawer-box {
    padding: 0 30px;
}

.tab /deep/ .el-table__body-wrapper {
    cursor: pointer;
}

.pro-box {
    margin-top: 15px;
    padding: 10px;
    border-radius: 8px;
    background-color: #f5f7fa;
}

.item-img {
    float: left;
    width: 118px;
    height: 118px;
    margin-right: 10px;
    overflow: hidden;
}

.item-img img {
    width: 100%;
}

.item-mes {
    width: calc(100% - 130px);
    float: left;
    position: relative;
}

.item-tit {
    color: #454545;
    font-size: 14px;
    overflow: hidden;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    height: 57px;
}

.item-price {
    margin-top: 40px;
}

.shopcart {
    position: absolute;
    right: 0;
    bottom: 0;
}

.detail-item {
    margin-bottom: 10px;
}

.detail-item .detail-tit {
    font-size: 16px;
}

.detail-item>p {
    margin: 5px 0;
}

.detail-item>p span {
    display: inline-block;
    width: 90px;
    text-align: left;
}
</style>
<style>
.el-select-dropdown__wrap,
.el-cascader-menu__wrap {
    margin-bottom: 0 !important;
}

.ql-snow .ql-picker-label::before {
    vertical-align: top;
}

.ql-container {
    height: 250px;
    overflow-y: auto;
}

.el-divider__text {
    background-color: #eff7f8;
}

.el-date-editor .el-range-separator {
    width: 10%;
}
</style>

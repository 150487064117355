let Referral = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    /**
     * 获取全部转诊信息
     * @param {any} success
     * @param {any} error
     */
    this.AllReferral = function (pageIndex, startTime, endTime, keyword,pageSize, success, error) {
        var url = ServiceBaseAddress + '/api/Referral/HP/All';
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (keyword) {
            url += `/${keyword}`
        } else {
            url += '/%20'
        }
        if (pageSize) { 
            url += `/${pageSize}`
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取转诊信息
     * @param {any} success
     * @param {any} error
     */
    this.getReferral = function (state, pageIndex, startTime, endTime, keyword,success, error) {
        var url = ServiceBaseAddress + '/api/Referral/HP/State/' + state;
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (keyword) {
            url += `/${keyword}`
        } else {
            url += '/%20'
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取待转诊信息
     * @param {any} success
     * @param {any} error
     */
    this.WaitReferral = function (success, error) {
        var pageIndex = 1;
        var url = ServiceBaseAddress + '/api/Referral/Manager/WaitReferral/' + pageIndex;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取待处理信息
     * @param {any} success
     * @param {any} error
     */
    this.WaitHandle = function (success, error) {
        var pageIndex = 1;
        var url = ServiceBaseAddress + '/api/Referral/Manager/WaitHandle/' + pageIndex;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取处理中列表信息
     * @param {any} success
     * @param {any} error
     */
    this.Handling = function (success, error) {
        var pageIndex = 1;
        var url = ServiceBaseAddress + '/api/Referral/Manager/Handling/' + pageIndex;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取已完成列表信息
     * @param {any} success
     * @param {any} error
     */
    this.Finish = function (success, error) {
        var pageIndex = 1;
        var url = ServiceBaseAddress + '/api/Referral/Manager/Finish/' + pageIndex;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 近期转诊
     * @param {any} success
     * @param {any} error
     */
    this.Recent = function (success, error) {
        var url = ServiceBaseAddress + '/api/Referral/HP/Recent';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 新增转诊
     * @param {any} name 姓名
     * @param {any} sex 性别
     * @param {any} age 年龄
     * @param {any} phoneNum 联系电话
     * @param {any} idcardNum 身份证号
     * @param {any} degree 病情程度
     * @param {any} diagnosis 初步诊断
     * @param {any} introduction 患者介绍
     * @param {any} success
     * @param {any} error
     */
    this.AddReferral = function (name, sex, age, phone, identity, degree, diagnosis, introduction, success, error) {
        var url = ServiceBaseAddress + '/api/Referral/Manager';
        var param = {
            name: name,
            sex: sex,
            age: age,
            phone: phone,
            identity: identity,
            degree: degree,
            diagnosis: diagnosis,
            introduction: introduction,
        };
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
    * 编辑转诊
     * @param {any} name 姓名
     * @param {any} sex 性别
     * @param {any} age 年龄
     * @param {any} phoneNum 联系电话
     * @param {any} idcardNum 身份证号
     * @param {any} degree 病情程度
     * @param {any} diagnosis 初步诊断
     * @param {any} introduction 患者介绍
     * @param {any} success
     * @param {any} error
    */
    this.EditReferral = function (referralId, name, sex, age, phone, identity, degree, diagnosis, introduction, success, error) {
        var url = ServiceBaseAddress + '/api/Referral/HP';
        var param = {
            referralId: referralId,
            name: name,
            sex: sex,
            age: age,
            phone: phone,
            identity: identity,
            degree: degree,
            diagnosis: diagnosis,
            introduction: introduction,
        };
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 删除转诊列表
     * @param {any} referralId
     * @param {any} success
     * @param {any} error
     */
    this.Remove = function (referralId, success, error) {
        var url = ServiceBaseAddress + '/api/Referral/HP/' + referralId;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 撤销上传
     * @param {any} referralId
     * @param {any} success
     * @param {any} error
     */
    this.Undo = function (referralId, success, error) {
        var url = ServiceBaseAddress + '/api/Referral/HP/Undo/' + referralId;
        TokenClient.Post(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 上传转诊
     * @param {any} referralId
     * @param {any} severity
     * @param {any} reason
     * @param {any} reasonDetails
     * @param {any} receptionDoctorId
     * @param {any} receptionHospitalId
     * @param {any} success
     * @param {any} error
     */
    this.Upload = function (referralId, severity, reason, reasonDetails, receptionDoctorId, receptionHospitalId, HospitalPhone, HospitalName, DepartmentName, DoctorPhone, DoctorName, success, error) {
        var url = ServiceBaseAddress + '/api/Referral/HP/Upload';
        var param = {
            referralId: referralId,
            severity: severity,
            reason: reason,
            reasonDetails: reasonDetails,
            receptionDoctorId: receptionDoctorId,
            receptionHospitalId: receptionHospitalId,
            receptionHospitalPhone: HospitalPhone,
            receptionHospitalName: HospitalName,
            receptionDermant: DepartmentName,
            receptionPhone: DoctorPhone,
            receptionName: DoctorName,
        };
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取转诊详情
     * @param {any} referralId
     * @param {any} success
     * @param {any} error
     */
     this.refeDetail = function (referralId, success, error) {
        var url = ServiceBaseAddress + '/api/Referral/HP/' + referralId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 获取时间轴
     * @param {any} referralId
     * @param {any} success
     * @param {any} error
     */
    this.TimeLine = function (referralId, success, error) {
        var url = ServiceBaseAddress + '/api/Reception/HP/TimeLine/' + referralId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}
export { Referral }
